import { fetcher } from "@website/fetcher";
import { ElementBodyType, ElementResponseType } from "./element.types";
const channel = process.env.NEXT_PUBLIC_CHANNEL_TYPE;
const adsType = process.env["NEXT_PUBLIC_ADS_TYPE"];

const channelBaseUrl =
  channel === "ads" && adsType === "intl-hotel"
    ? "https://ih.snapptrip.live/international-hotel/api"
    : channel === "ads"
      ? "https://hotelapi.snapptrip.info"
      : "https://hapi.snapptrip.com";

export const ElementUrl = `${channelBaseUrl}/service2/seo/seo/page/element`;

export const ElementService = (
  data: ElementBodyType
): Promise<ElementResponseType> =>
  fetcher({
    url: ElementUrl,
    method: "POST",
    data
  })
    .then((response) => response.data)
    .catch(() => null);
